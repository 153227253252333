html, body {
  margin: 0;
  font-family: 'Droid Arabic Naskh, serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;

  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;

  font-weight: 900;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*blurry background for mobile, and pinned to bottom. */
@media only screen and (max-width: 500px) {
  .polotno-panel-container {
    position: absolute;
    bottom: 0 !important;

    background-color: rgba(255,255,255,0.7) !important;
    backdrop-filter: blur(10px);
  }
}

/*change default fontfamily*/
button {
  font-family: inherit !important;
}

/* Remove tabs container */
.polotno-side-tabs-container {
  display: none;
}


button{
  user-select:none;
}


body[lang=ar] span.bidi{
  display: block;
  direction: rtl;
}


/* #subscribeNowButton, #backToDesignsButton{
  background-color: rgba(255,255,255,0.1);
}

#subscribeNowButton:hover, #backToDesignsButton:hover{
  background-color: rgba(255,255,255,0.2);
} */



html, body {
  /* background-color: #e8e8e8; */
}

/*RTL Support*/
/* body[lang='ar']{
  text-align: right;
}

body[lang='ar'] .bp4-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
}

body[lang='ar'] .bp4-menu-item, body[lang='ar'] .bp4-navbar-group {
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  text-align: right;
}


.bp4-menu-item-icon, .bp4-tab {
  margin-inline-end: 5px;
}

.bp4-navbar-group > div {
  display: flex;
  flex-direction: row-reverse;
} */


html[lang='ar'] .subscribe-dialog {
  text-align: right !important;
}
